<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item
        :to="{
          path: '/academic_Management/academic_font_management/academic_font_management_home',
        }"
        >学术系统</el-breadcrumb-item
      >
      <el-breadcrumb-item>题型管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <!-- 题型列表 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="5">
          <span class="title-class title_class">题型列表</span>
        </el-col>
        <el-col :span="19" style="text-align: right">
          <el-button type="primary" @click="addQuestionTypeDialog = true"
            >新增题型+</el-button
          >
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="questionTypeListData"
        border
        stripe
      >
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column label="题型名称" prop="name"></el-table-column>
        <el-table-column label="显示名称" prop="showName"></el-table-column>
        <el-table-column label="题型英文名称" prop="enName"></el-table-column>
        <el-table-column label="题型描述" prop="discribe"></el-table-column>
        <el-table-column label="答题时间" prop="answerTime"></el-table-column>
        <el-table-column label="题型标识" prop="flag"></el-table-column>
        <el-table-column label="题型分数" prop="score"></el-table-column>
        <el-table-column
          label="所属赛程"
          prop="raceSchedule"
          :formatter="raceScheduleFormatter"
        ></el-table-column>
        <el-table-column label="更新时间" prop="updateTime"></el-table-column>
        <el-table-column label="操作" width="140px">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-edit"
              size="mini"
              @click="editDistrictMessage(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              icon="el-icon-delete"
              size="small"
              style="color: #f56c6c"
              @click="deleteDialog(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog
      :title="isEdit == true ? '编辑题型' : '新增题型'"
      :visible.sync="addQuestionTypeDialog"
      width="50%"
      @close="editQuestionTypeDialogClose"
    >
      <el-form
        :model="addInfoForm"
        label-width="100px"
        ref="addInfoFormRef"
        :rules="addInfoRules"
        style="width: 100%"
      >
        <el-row>
          <el-col :span="20">
            <el-form-item label="题型名称" prop="name">
              <el-input v-model="addInfoForm.name" placeholder="请输入名称">
              </el-input>
            </el-form-item>
            <el-form-item label="显示名称" prop="showName">
              <el-input v-model="addInfoForm.showName" placeholder="请输入名称">
              </el-input>
            </el-form-item>
            <el-form-item label="英文名称" prop="enName">
              <el-input
                v-model="addInfoForm.enName"
                placeholder="请输入英文名称"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="题型描述" prop="discribe">
              <el-input
                v-model="addInfoForm.discribe"
                placeholder="请输入提醒描述"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="题型分数" prop="score">
              <el-input v-model="addInfoForm.score" placeholder="请输入分数">
              </el-input>
            </el-form-item>
            <el-form-item label="答题时间" prop="answerTime">
              <el-input
                v-model="addInfoForm.answerTime"
                placeholder="请输入答题时间(秒)"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="标识" prop="flag">
              <el-input
                v-model="addInfoForm.flag"
                placeholder="请输入标识"
                :disabled="isEdit"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="所属赛程" prop="raceSchedule">
              <el-select
                v-model="addInfoForm.raceSchedule"
                placeholder="请选择赛程"
                clearable
                style="width: 100%"
                ><el-option
                  v-for="item in raceScheduleList"
                  :key="item.scheduleSort"
                  :label="item.scheduleName"
                  :value="Number(item.id)"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="题型图片:" prop="discribePic">
            <el-upload
              :action="uploadTypeImageResults.uploadUrl"
              :show-file-list="false"
              :on-success="handleUploadSimpleImgSuccess"
              :before-upload="beforeImgUpload"
              :headers="uploadTypeImageResults.headerObj"
              :data="{
                filePath: 'spbcnapp/questionTypeImage/',
              }"
            >
              <el-image
                v-if="addInfoForm.discribePic"
                style="width: 100px; height: 100px"
                :src="addInfoForm.discribePic"
                fit="object-fit"
              >
              </el-image>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addQuestionTypeDialog = false">取 消</el-button>
        <el-button type="primary" @click="addQuestionType">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { questionTypeList, getQuestionType, addQuestionType, deleteQuestionType } from '@/http/api'
export default {
  data () {
    return {
      uploadTypeImageResults: {
        uploadUrl: this.$http.defaults.baseURL + 'upload/uploadFileAliOss',
        headerObj: {
          Authorization: localStorage.getItem('Authorization')
        }
      },
      isEdit: false,
      uploadImgUrl: '',
      addInfoRules: {
        name: [{ required: true, message: '请输入题型名称', trigger: 'blur' }],
        showName: [{ required: true, message: '请输入外显名称', trigger: 'blur' }],
        enName: [{ required: true, message: '请输入题型名称', trigger: 'blur' }],
        discribe: [{ required: true, message: '请输入题型描述', trigger: 'blur' }],
        discribePic: [{ required: true, message: '请选择题型图片', trigger: 'change' }],
        answerTime: [{ required: true, message: '请输入答题时间', trigger: 'blur' }],
        flag: [{ required: true, message: '请输入题型flag', trigger: 'blur' }],
        raceScheduleIng: [{ required: true, message: '请选择赛程', trigger: 'change' }],
        score: [{ required: true, message: '请输入题型flag', trigger: 'blur' }]
      },
      addQuestionTypeDialog: false,
      addInfoForm: {},
      raceScheduleList: [],
      questionTypeListData: []
    }
  },
  created () {
    this.getQuestionTypeList()
    this.raceScheduleList = this.$chnEngPublicRequest.querySeasonRaceSchedule(this, { activityType: '1' }).then(value => { this.raceScheduleList = value })
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    // 获取题型列表
    getQuestionTypeList () {
      questionTypeList({ status: true }).then((res) => {
        this.questionTypeListData = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 添加题型确定点击事件
    addQuestionType () {
      addQuestionType(this.addInfoForm).then((res) => {
        this.addQuestionTypeDialog = false
        this.$message.success('添加成功')
        this.getQuestionTypeList()
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 编辑点击事件
    editDistrictMessage (rowData) {
      getQuestionType(rowData.id).then((res) => {
        this.addInfoForm = res.data
        this.isEdit = true
        this.addQuestionTypeDialog = true
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    editQuestionTypeDialogClose () {
      this.isEdit = false
      this.addInfoForm = {}
      this.$refs.addInfoFormRef.resetFields()
    },
    // 删除点击事件
    deleteDialog (rowId) {
      this.$confirm('是否确认删除该题型?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        deleteQuestionType(rowId).then((res) => {
          this.$message.success('删除成功')
          this.getQuestionTypeList()
        }).catch((err) => {
          console.log('err', err)
        })
      }).catch(() => { })
    },
    handleUploadSimpleImgSuccess (res, file) {
      this.$set(this.addInfoForm, 'discribePic', res.data)
    },
    beforeImgUpload (file) {
      const isJPGPNG = (file.type === 'image/jpeg' || file.type === 'image/png')
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isJPGPNG) {
        this.$message.error('上传图片只能是 JPG、PNG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return isJPGPNG && isLt2M
    },
    raceScheduleFormatter (data) {
      var temp = '未设置'
      for (const key in this.raceScheduleList) {
        if (Object.hasOwnProperty.call(this.raceScheduleList, key)) {
          const element = this.raceScheduleList[key]
          if (Number(element.id) === data.raceSchedule) {
            temp = element.scheduleName
          }
        }
      }
      return temp
    }
  }
}
</script>

<style lang="less" scoped>
.wordImg {
  width: 100px;
  height: 100px;
}
.avatar {
  width: 100px;
  height: 100px;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px solid #8c939d;
}
</style>
